<template>
  <div>
    <v-row class="pl-5" style="align-items: start" no-gutters>
      <v-col align-self="start" cols="auto">
        <span class="text-h6 font-weight-regular"> {{ titleBase }}</span>
      </v-col>
      <v-col cols="auto" class="pl-0 pt-4">
        <v-tooltip top v-if="editMode">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="pb-1 text-lowercase"
              :disabled="!markedTimesteps.length"
              text
              v-bind="attrs"
              v-on="on"
              @click="saveMarkedTimesteps"
            >
              {{ $t("baseline.deleteSelected") }}
            </v-btn>
          </template>
          <span>{{ $t("baseline.deleteSelectedPoints") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="editMode">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!oldMarkedTimesteps.length"
              :color="oldMarkedTimesteps.length ? 'primary' : ' grey'"
              class="px-1 text-lowercase"
              dark
              x-small
              icon
              text
              v-bind="attrs"
              v-on="on"
              @click="resetAllTimesteps"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("baseline.resetChanges") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div
      ref="plot"
      :id="container"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '150px'
      }"
    ></div>
  </div>
</template>

<script>
import plotly from "plotly.js-dist";
import dataManagementMixin from "@/core/mixins/dataManagement.mixin";
import { mapActions, mapState } from "vuex";

export default {
  name: "DataPlot",
  mixins: [dataManagementMixin],
  props: {
    timeLayer: String,
    container: String,
    thresholds: Boolean,
    titleBase: String,
    titleXaxis: String,
    titleYaxis: String,
    jsonFetch: Object,
    productLayer: String,
    editMode: Boolean,
    virtualStation: Object,
    inSituData: Object,
    showInSituData: Boolean
  },
  data: () => ({
    plotData: [],
    plotLayout: [],
    plotJsonFetch: null,
    markedTimesteps: [],
    addedTimestep: [],
    oldMarkedTimesteps: [],
    inSituDataToPlot: []
  }),
  computed: {
    chart() {
      return {
        traces: this.dataCache,
        layout: {
          title: "Data Plot"
        }
      };
    },
    ...mapState("inSitu", ["singleColorInSituData"])
  },
  //https://plotly.com/javascript/plotlyjs-function-reference/
  methods: {
    ...mapActions("app", ["showSnackbar"]),

    createPlot() {
      const title = {
        titleXaxis: this.titleXaxis || "",
        titleYaxis: this.titleYaxis || ""
      };

      const { data, layout } = this.getPLotyFormat(title, this.plotJsonFetch);
      this.plotData = data;
      this.plotLayout = layout;

      const productLayer = this.productLayer;

      var config = {
        modeBarButtonsToRemove: [
          "toggleHover",
          "sendDataToCloud",
          "toggleSpikelines",
          "resetScale2d",
          "lasso2d",
          "pan2d",
          "zoom2d",
          "select2d"
        ],
        responsive: true
      };

      plotly.newPlot(this.container, data, layout, config);
      // Get rastless datetime from plot
      this.addDefaultClickEvent(productLayer);
      this.loading = false;
    },

    addDefaultClickEvent(productLayer) {
      const _this = this;
      this.$refs.plot.on("plotly_click", function(data) {
        if (data.points.length > 0) {
          data.points.forEach(point => {
            if (point.customdata && point.customdata !== "sourceInSituData") {
              _this.$emit("clickedDatetime", point.customdata);
              _this.$emit("clickedProductLayer", productLayer);
            }
          });
        }
      });
    },
    updatePlot(data, layout) {
      plotly.react(this.container, data, layout);
    },
    getPLotyFormat(param, plotJsonFetch) {
      let { titleXaxis, titleYaxis } = param;
      let plotData = {
        x: [],
        customdata: [],
        y: [],
        color: [],
        size: [],
        symbol: [],
        warning: [],
        alert: [],
        datetime: []
      };
      plotJsonFetch["statistics"].map(item => {
        plotData.x.push(item.scene_date);
        plotData.customdata.push(item.scene_date);
        plotData.y.push(item.value);
        plotData.color.push(
          (function() {
            return item.status === "warning"
              ? "rgb(234,170,0)"
              : item.status === "alert"
              ? "red"
              : "green";
          })()
        );
        plotData.size.push(7);
        plotData.symbol.push("circle");
        plotData.datetime.push(this.timeLayer);
        plotData.warning.push(
          plotJsonFetch["thresholds"]?.["warning"]?.["value"]
        );
        plotData.alert.push(plotJsonFetch["thresholds"]?.["alert"]?.["value"]);
        return;
      });
      let plotlyFormat = {
        data: [
          {
            type: "scatter",
            mode: "markers+text",
            automargin: true,
            marker: {
              size: plotData.size,
              color: plotData.color,
              symbol: plotData.circle,
              opacity: 0.9
            },
            x: plotData.x,
            y: plotData.y,
            name: this.$t("dashboardDetails.value"),
            customdata: plotData.customdata
          },
          {
            type: "scatter",
            mode: "lines",
            x: plotData.datetime,
            y: plotData.y,
            name: this.$t("dashboardDetails.date"),
            line: {
              color: "blue",
              width: 1.5
            }
          },
          {
            type: "scatter",
            mode: "lines",
            x: plotData.x,
            y: plotData.warning,
            name: this.$t("dashboardDetails.warningLimit"),
            line: {
              dash: "dashdot",
              color: "rgba(254,204,92,1)",
              width: 2.0
            }
          },
          {
            type: "scatter",
            mode: "lines",
            x: plotData.x,
            y: plotData.alert,
            name: this.$t("dashboardDetails.alertLimit"),
            line: {
              color: "rgba(240,59,32,1)",
              width: 1.5
            }
          }
        ],
        layout: {
          xaxis: {
            type: "date",
            title: titleXaxis,
            titlefont: {
              size: 14,
              color: "rgba(0,0,0,0.8)"
            }
          },
          yaxis: {
            side: "bottom",
            autorange: true,
            range: [0, 8],
            type: "linear",
            title: {
              text: titleYaxis,
              standoff: 10
            },
            titlefont: {
              size: 12,
              color: "rgba(53,76,120,1)"
            }
          },
          margin: { autoexpand: false, l: 65, r: 10, b: 35, t: 19, pad: 4 },
          showlegend: false,
          hovermode: "closest"
        }
      };
      return plotlyFormat;
    },
    addClickEvent() {
      var plotDiv = document.getElementById(this.container);
      const mode = this.editMode;
      let colors = [];
      let addedTimestep = this.addedTimestep;
      plotDiv.on("plotly_click", data => {
        if (mode) {
          data.points.forEach(point => {
            const index = data.points[0].pointIndex;

            addedTimestep.push(point.data.x[index]);
            point.data.x.forEach((x, idx) => {
              if (colors.length < point.data.x.length) {
                colors.push(point.data.marker.color);
              } else {
                colors[idx] = point.data.marker.color[idx];
              }
            });
            colors[index] = colors[index] === "#3584BB" ? "#F6A124" : "#3584BB";

            var update = {
              marker: {
                color: colors,
                line: {
                  color: "#fff",
                  width: 0.8
                },
                size: 7
              }
            };
            plotly.restyle(
              plotDiv,
              update,
              JSON.parse(JSON.stringify(this.plotData[0]))
            );
          });
        }
      });
    },
    manageEditMode() {
      let plotDiv = document.getElementById(this.container);
      plotDiv.removeAllListeners("plotly_click");
      if (!this.editMode && !this.thresholds) {
        const restore = {
          marker: {
            color: "#3584BB",
            size: 7,
            opacity: 0.9,
            line: {
              color: "white",
              width: 0.8
            }
          }
        };
        plotly.restyle(
          this.container,
          restore,
          JSON.parse(JSON.stringify(this.plotData[0]))
        );
        this.addDefaultClickEvent(this.productLayer);
      } else if (this.editMode) {
        this.markedTimesteps = [];
        const update = {
          marker: {
            color: "#3584BB",
            line: {
              color: "#fff",
              width: 0.8
            },
            size: 7
          }
        };
        plotly.restyle(
          this.container,
          update,
          JSON.parse(JSON.stringify(this.plotData[0]))
        );
        this.addClickEvent();
      } else if (!this.editMode) {
        this.addDefaultClickEvent(this.productLayer);
      }
    },
    async saveMarkedTimesteps() {
      await this.markTimestepsAsDeleted(
        this.$route.params.regionId,
        this.productLayer,
        this.virtualStation.id,
        this.markedTimesteps
      );
      this.markedTimesteps = [];
      this.$emit("toggleReload");
    },
    async resetAllTimesteps() {
      await this.resetMarkedTimesteps(
        this.productLayer,
        this.virtualStation.id
      );
      this.$emit("toggleReload");
    },
    createInSituDataPlot(datetimes, values) {
      this.inSituDataToPlot = {
        type: "scatter",
        mode: "markers",
        marker: {
          color: this.singleColorInSituData,
          size: 7,
          symbol: "diamond"
        },
        x: datetimes,
        y: values,
        customdata: ["sourceInSituData"]
      };
      if (!this.thresholds) {
        const dataNoThresholds = JSON.parse(JSON.stringify(this.plotData[0]));
        const currentSceneVerticalLine = JSON.parse(
          JSON.stringify(this.plotData[1])
        );
        dataNoThresholds.marker.color = "#3584BB";
        this.updatePlot(
          [
            dataNoThresholds,
            currentSceneVerticalLine,
            ...JSON.parse(JSON.stringify([this.inSituDataToPlot]))
          ],
          this.plotLayout
        );
      } else {
        this.updatePlot(
          [
            ...JSON.parse(JSON.stringify(this.plotData)),
            ...JSON.parse(JSON.stringify([this.inSituDataToPlot]))
          ],
          this.plotLayout
        );
      }
    },
    hasInSituData() {
      return (
        this.inSituData &&
        this.inSituData.values &&
        this.inSituData.values.length > 0
      );
    }
  },

  watch: {
    async save() {
      await this.saveMarkedTimesteps();
    },
    editMode() {
      this.manageEditMode();
    },
    addedTimestep() {
      const newTimestep = this.addedTimestep[this.addedTimestep.length - 1];
      if (this.markedTimesteps.includes(newTimestep)) {
        const index = this.markedTimesteps.indexOf(newTimestep);
        this.markedTimesteps.splice(index, 1);
      } else [this.markedTimesteps.push(newTimestep)];
    },
    timeLayer() {
      this.plotData[1].x.forEach((value, index) => {
        this.plotData[1].x[index] = this.timeLayer;
      });

      if (!this.thresholds) {
        const dataNoThresholds = JSON.parse(JSON.stringify(this.plotData[0]));
        const currentSceneVerticalLine = JSON.parse(
          JSON.stringify(this.plotData[1])
        );
        dataNoThresholds.marker.color = "#3584BB";
        if (!this.showInSituData) {
          this.updatePlot(
            [dataNoThresholds, currentSceneVerticalLine],
            this.plotLayout
          );
        } else {
          this.updatePlot(
            [
              dataNoThresholds,
              currentSceneVerticalLine,
              ...JSON.parse(JSON.stringify([this.inSituDataToPlot]))
            ],
            this.plotLayout
          );
        }
      } else {
        if (!this.showInSituData) {
          this.updatePlot(
            JSON.parse(JSON.stringify(this.plotData)),
            this.plotLayout
          );
        } else {
          this.updatePlot(
            [
              ...JSON.parse(JSON.stringify(this.plotData)),
              ...JSON.parse(JSON.stringify([this.inSituDataToPlot]))
            ],
            this.plotLayout
          );
        }
      }
    },
    thresholds(value) {
      if (!value) {
        // TODO: better way of changing the color?
        const dataNoThresholds = JSON.parse(JSON.stringify(this.plotData[0]));
        const currentSceneVerticalLine = JSON.parse(
          JSON.stringify(this.plotData[1])
        );

        dataNoThresholds.marker.color = "#3584BB";

        if (!this.showInSituData) {
          this.updatePlot(
            [dataNoThresholds, currentSceneVerticalLine],
            this.plotLayout
          );
        } else {
          this.updatePlot(
            [
              dataNoThresholds,
              currentSceneVerticalLine,
              ...JSON.parse(JSON.stringify([this.inSituDataToPlot]))
            ],
            this.plotLayout
          );
        }
      } else {
        if (!this.showInSituData) {
          this.updatePlot(this.plotData, this.plotLayout);
        } else {
          this.updatePlot(
            [
              ...JSON.parse(JSON.stringify(this.plotData)),
              ...JSON.parse(JSON.stringify([this.inSituDataToPlot]))
            ],
            this.plotLayout
          );
        }
      }
    },
    plotJsonFetch(value) {
      if (value != null) {
        let events = { statistics: false };
        let key = "statistics";
        if (Object.prototype.hasOwnProperty.call(events, key)) {
          this.createPlot();
          if (!this.thresholds) {
            // TODO: better way of changing the color?
            const dataNoThresholds = JSON.parse(
              JSON.stringify(this.plotData[0])
            );
            const currentSceneVerticalLine = JSON.parse(
              JSON.stringify(this.plotData[1])
            );
            dataNoThresholds.marker.color = "#3584BB";
            this.updatePlot(
              [dataNoThresholds, currentSceneVerticalLine],
              this.plotLayout
            );
          }
        }
      }
    },
    showInSituData(value) {
      if (value) {
        if (this.hasInSituData()) {
          this.createInSituDataPlot(
            this.inSituData.date_times,
            this.inSituData.values
          );
        }
        let lay = { xaxis: { autorange: true }, autosize: true };
        plotly.relayout(this.container, lay);
      } else {
        const dataNoThresholds = JSON.parse(JSON.stringify(this.plotData[0]));
        const currentSceneVerticalLine = JSON.parse(
          JSON.stringify(this.plotData[1])
        );

        dataNoThresholds.marker.color = "#3584BB";

        if (!this.thresholds) {
          this.updatePlot(
            [dataNoThresholds, currentSceneVerticalLine],
            this.plotLayout
          );
        } else {
          this.updatePlot(
            [...JSON.parse(JSON.stringify(this.plotData))],
            this.plotLayout
          );
        }

        let lay = { xaxis: { autorange: true }, autosize: true };
        plotly.relayout(this.container, lay);
      }
    }
  },
  async created() {
    this.plotJsonFetch = this.jsonFetch;
    this.oldMarkedTimesteps = await this.getMarkedTimesteps(
      this.$route.params.regionId,
      this.productLayer,
      this.virtualStation.id
    );
    this.manageEditMode();

    if (this.hasInSituData() && this.showInSituData) {
      this.createInSituDataPlot(
        this.inSituData.date_times,
        this.inSituData.values
      );
    }
  }
};
</script>

<style scoped></style>
