import { render, staticRenderFns } from "./DataPlotDashboard.vue?vue&type=template&id=24f929bb&scoped=true"
import script from "./DataPlotDashboard.vue?vue&type=script&lang=js"
export * from "./DataPlotDashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f929bb",
  null
  
)

export default component.exports