var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","height":"100%"},attrs:{"id":"parent_container"}},[_c('div',{ref:"plot",style:({
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      flex: 1
    }),attrs:{"id":_vm.container}})])
}
var staticRenderFns = []

export { render, staticRenderFns }